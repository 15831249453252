import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";

import { CommonHead } from "../../../components/commons/Head/Head";

import { KoreanDivisionAbstruct } from "../../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../../components/elements/Hr/Hr";
import {
  KLHeader,
  KLBox,
  KLCardHeader,
  KLCard,
  KLNumberHeader,
  KLNumbers,
  KLButtons,
  KLSection1,
  KLSection2,
  KLSection3,
} from "../../../components/elements/KoreanLeaf/KoreanLeaf";
import LeftBar from "../../../components/elements/LeftBar/LeftBar";
import SnsShare from "../../../components/elements/SnsShare/SnsShare";
import Vimeo from "../../../components/elements/Vimeo/Vimeo";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <>
        {/* メイン動画 */}
        <Vimeo
          vimeoId="944314133"
          privacyHash="e43d6c8741"
          autoPlay
          controls
          muted
        />
        {/* 韓国語用リーフヘッダー */}
        <KLHeader
          bgColorClass="tw-bg-[#B767C5]"
          koreanTitle="만나다"
          japaneseTitle="出会う"
          anchorList={[
            { id: "yuujin", title: "友人" },
            { id: "sensei", title: "先生" },
            { id: "jcfl", title: "JCFL" },
          ]}
          imageSrc="/images/korean/kv‐meet.jpg"
        />
        <KLBox sentence="「韓国が好き！」という気持ちを大切に、共感できる友人や先生と会える場所。" />
        <Hr />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <KLCardHeader title="友人" id="yuujin" />
            <KLCard
              bgColorClass="tw-bg-[#FCEAFF]"
              imageSrc="/images/korean/1-1-meet.JPG"
              title="「韓国」で繋がる友人と出会うことができる場所"
              lines={[
                "「韓国好き」が集まるクラスで楽しく学ぶ",
                "高めあえる存在に出会える",
                "現地の大学生や留学生との交流で、異文化を知る",
              ]}
            />
            <KLCardHeader title="先生" id="sensei" />
            <KLCard
              bgColorClass="tw-bg-[#FCEAFF]"
              imageSrc="/images/korean/1-2-meet.JPG"
              title="韓国人教員 ✖ 日本人教員のダブルサポート"
              lines={[
                "担任・副担任がいる安心感",
                "勉強、生活、進路など、何でも相談できる先生がいる",
                "韓国、韓国語のプロフェッショナルが勢ぞろい",
              ]}
            />
            <KLCardHeader title="JCFL" id="jcfl" />
            <KLCard
              bgColorClass="tw-bg-[#FCEAFF]"
              imageSrc="/images/korean/1-3-meet.JPG"
              title="グローバルキャンパス「早稲田新館」"
              lines={[
                "アジア・ヨーロッパ言語科のメインキャンパス",
                "いつでもネイティブの先生と出会える",
                "開放的で明るいキャンパスで伸び伸びと学ぶ",
              ]}
            />
          </ResponsiveBlock>
        </LeftBar>
        <Hr />
        <ResponsiveBlock bgColor="">
          <KLNumberHeader title="数字で見る" id="suujidemiru" />
          <KLNumbers
            items={[
              {
                title: "ネイティブ",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "20",
                unitBack: "人以上",
                description:
                  "毎日ネイティブの先生と韓国語に触れられ、日本にいながらも韓国にいるような日々を過ごせます。",
              },
              {
                title: "仲 間",
                titleSmaller: "",
                subtitle: "",
                unitFront: "",
                unitSide: "",
                unitSideSmaller: false,
                threeDigits: false,
                number: "40",
                unitBack: "都道府県",
                description:
                  "日本全国から学生が集まります。韓国好きのクラスメイトと韓国潰けの毎日が待っています!",
              },
            ]}
            marginTopHalf
          />
        </ResponsiveBlock>
        <Hr />
        <KLButtons
          buttons={[
            { name: "感じる", url: "/korean/feel/" },
            { name: "学ぶ", url: "/korean/learn/" },
            { name: "叶う", url: "/korean/realize/" },
          ]}
        />
        <Hr />
        <SnsShare
          path="/korean"
          title={title}
          buttonName="戻る"
          buttonUrl="/korean"
          spaceBottom
        />
        <Hr />
        <KoreanDivisionAbstruct spaceBottom />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`出会う - 韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="「韓国が好き！」という気持ちを大切に、共感できる友人や先生と会える場所"
    ogImageFilepath="/images/korean/kv‐meet.jpg"
  >
    <title>
      出会う - 韓国語＋英語専攻 - {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
